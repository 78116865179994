.title {
  padding: 0px 10px;
  color: #78b428;
}

.panel {
  position: relative;
  border: 1px solid #c8c8c8;
  border-radius: 4px;
  padding: 0px 20px;
}

.option {
  padding: 20px 0;
  border-top: 1px solid #c8c8c8;
}

.selected {
  border: 1px solid #fac83c;
}
.optionHeader {
  margin: 20px 0px;
}
.radioInput {
  display: none;
}
.radioInput + p,
.radioInput + div {
  width: 100%;
  padding-left: 35px;
  position: relative;
  margin-right: 15px;
}
.radioInput + p:before,
.radioInput + div:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
  margin: auto;
  width: 25px;
  height: 25px;
  border: 1px solid #c8c8c8;
  border-radius: 50%;
}

.radioInput:checked + p:before,
.radioInput:checked + div:before {
  border: 0;
  background-color: #fac83c;
}

.radioInput:checked + p:after,
.radioInput:checked + div:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 9px;
  width: 7px;
  height: 11px;
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
}

.disabled {
  background-color: #f0f0f0;
}

.descriptionContainer {
  padding-top: 10px;
}

.descriptionLink {
  text-decoration: underline;
  cursor: pointer;
}

.caution {
  white-space: pre-line;
}
